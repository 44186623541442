<template>
  <!-- 产线人员 -->
  <div class="chanXianPersonnel_content">
    <van-nav-bar :left-text="pageTitle"
                 left-arrow
                 @click-left="onClickLeft"
                 :fixed="true">
    </van-nav-bar>
    <div class="content">
      <div class="table_box"
           style="overflow: auto;background: #fff;">
        <table ref="table"
               class="table">
          <!-- 表头 -->
          <tr class="top">
            <th v-for="(item_th, index_th) in autoThList"
                :key="index_th">
              <div class="title"
                   :style="item_th.gridwith ? { width: item_th.gridwith * 1.5 + 'px' } : 'min-width:90px'">
                {{ item_th.chnname }}
              </div>
            </th>
          </tr>
          <!--展示列表数据 -->
          <tr class="cont"
              v-for="(item_tr, index_tr) in autoTableData"
              :key="index_tr"
              @click.stop="changeAuditDataByTd(item_tr)">
            <td v-for="(item_td, index_td) in autoThList"
                :key="index_td">
              <div class="comm_box">
                <div class="boolean_box"
                     v-if="item_td.datatype == 'checkbox'">
                  <van-checkbox v-model="item_tr[item_td.fieldname]"></van-checkbox>
                </div>
                <div class="input_box"
                     v-else-if="item_td.datatype == 'num'">
                  <van-field v-model="item_tr[item_td.fieldname]"
                             type="number"
                             :placeholder="item_td.chnname" />
                </div>
                <div class="input_box"
                     v-else-if="item_td.datatype == 'select'">
                  <van-field v-model="item_tr[item_td.fieldname]"
                             readonly
                             right-icon="arrow-down"
                             :placeholder="item_td.chnname"
                             @click="checkEmp(item_tr)" />
                </div>
                <div v-else>{{ item_tr[item_td.fieldname] }}</div>
              </div>
            </td>
          </tr>
          <tr class="nodata"
              v-if="autoTableData.length == 0">
            <td align="center"
                :colspan="autoThList.length+1">暂无数据</td>
          </tr>
        </table>
      </div>
    </div>
    <!-- 按钮 -->
    <div class="footer">
      <div class="f_btn"
           @click="checkFoot()"
           style="border-right: 1px solid #d2d2d2;">
        <van-icon name="add"
                  color="#1989fa" />
        <div>增加人员</div>
      </div>
      <div class="f_btn"
           @click="checkFoot('no')">
        <van-icon name="delete"
                  color="#ff976a" />
        <div>删除人员</div>
      </div>
    </div>

    <!-- 新增人员 -->
    <van-dialog v-model="showAddDia"
                :title="'新增人员'"
                class="addNewPerson_dia"
                show-cancel-button
                confirm-button-text='保存'
                confirm-button-color='#0782F5'
                :beforeClose="submitAddPerson">
      <div class="add_content">
        <van-form label-align="right">
          <van-field readonly
                     clickable
                     required
                     label="车间"
                     right-icon="arrow-down"
                     v-model="addForm.workshop"
                     placeholder="选择车间"
                     @click="showCjPicker = true" />
          <van-field readonly
                     clickable
                     required
                     label="产线名"
                     right-icon="arrow-down"
                     v-model="addForm.linename"
                     placeholder="选择产线"
                     :disabled="!addForm.workshop"
                     @click="()=>{addForm.workshop?showCxPicker = true:''}" />
          <van-field readonly
                     clickable
                     label="产线号"
                     v-model="addForm.linecode" />
          <van-field readonly
                     required
                     clickable
                     label="工号"
                     right-icon="arrow-down"
                     v-model="addForm.empcode"
                     placeholder="选择工号"
                     @click="showEmpTreePop " />
          <van-field readonly
                     clickable
                     label="姓名"
                     v-model="addForm.empname" />
          <van-field readonly
                     clickable
                     name="calendar"
                     right-icon="arrow-down"
                     v-model="addForm.bd"
                     label="开始日期"
                     placeholder="选择日期"
                     @click="checkExDate('bd')" />
          <van-field readonly
                     clickable
                     name="calendar"
                     right-icon="arrow-down"
                     v-model="addForm.ed"
                     label="结束日期"
                     placeholder="选择日期"
                     @click="checkExDate('ed')" />
          <van-field v-model="addForm.remark"
                     rows="2"
                     autosize
                     type="textarea"
                     label="备注"
                     placeholder="备注" />
        </van-form>
      </div>
    </van-dialog>
    <!-- 车间下拉弹窗 -->
    <van-popup v-model="showCjPicker"
               round
               position="bottom">
      <van-picker show-toolbar
                  :columns="workshopList"
                  value-key="workshop"
                  :confirm-button-text="$t('module.confirm')"
                  :cancel-button-text="$t('module.cancel')"
                  @cancel="showCjPicker = false"
                  @confirm="onConfirmCj" />
    </van-popup>
    <!-- 产线下拉弹窗 -->
    <van-popup v-model="showCxPicker"
               round
               position="bottom">
      <van-picker show-toolbar
                  :columns="lineList"
                  value-key="linename"
                  :confirm-button-text="$t('module.confirm')"
                  :cancel-button-text="$t('module.cancel')"
                  @cancel="showCxPicker = false"
                  @confirm="onConfirmCx" />
    </van-popup>
    <!-- 日期日历弹窗 -->
    <van-calendar v-model="showCalendar"
                  :type="'single'"
                  :min-date="minDate"
                  :max-date="maxDate"
                  @confirm="onConfirmCal" />
    <!-- 选择产线人员弹窗 -->
    <PersonnelPop ref="refPersonnelPop"
                  :single="true"
                  @getPersonnel="getPersonnel"></PersonnelPop>
  </div>

</template>

<script>
import { Toast, Dialog } from "vant";
import PersonnelPop from "@components/selectPersonnel/index.vue";
import { getLineListDetail, getWorkShopList, getWorkShopLineList, saveLineEmpRecord, deleteLineEmpRecord } from '@api/wxjj.js'
export default {
  components: {
    PersonnelPop
  },
  data () {
    const userInfo = localStorage.userInfo ? JSON.parse(localStorage.userInfo) : {}
    return {
      userInfo,
      showCalendar: false,
      minDate: new Date(new Date().getFullYear() - 20, 0, 1),
      maxDate: new Date(new Date().getFullYear() + 20, 0, 31),
      witchDate: 'bd',
      autoThList: [
        {
          fieldname: "checked",
          chnname: "",
          gridwith: 10,
          datatype: 'checkbox'
        },
        {
          fieldname: "sqNum",
          chnname: "序号",
          gridwith: 30,
          datatype: 'str'
        },
        {
          fieldname: "workshop",
          chnname: "车间",
          gridwith: 60,
          datatype: 'str'
        },
        {
          fieldname: "linecode",
          chnname: "产线号",
          gridwith: 60,
          datatype: 'str'
        },
        {
          fieldname: "linename",
          chnname: "产线名",
          gridwith: 60,
          datatype: 'str'
        },
        {
          fieldname: "empcode",
          chnname: "工号",
          gridwith: 50,
          datatype: 'str'
        },
        {
          fieldname: "empname",
          chnname: "姓名",
          gridwith: 50,
          datatype: 'str'
        }
      ],
      autoTableData: [],
      addForm: {
        workshopid: null,
        workshop: "",
        lineid: "",
        linecode: "",
        linename: "",
        empid: "",
        empcode: "",
        empname: "",
        bd: "",
        ed: "",
        remark: ""
      },
      showAddDia: false,
      showCjPicker: false,
      showCxPicker: false,
      workshopList: [],
      lineList: []
    }
  },
  computed: {
    pageTitle () {
      let title = '产线人员'
      if (this.$route.query)
      {
        title = this.$route.query.title
      }
      return title
    }
  },
  watch: {
    showAddDia (n) {
      if (!n)
      {
        this.addForm = {
          workshopid: null,
          workshop: "",
          lineid: "",
          linecode: "",
          linename: "",
          empid: "",
          empcode: "",
          empname: "",
          bd: "",
          ed: "",
          remark: ""
        }
      }
    }
  },
  created () {
    this.getLineListDetailData()
  },
  methods: {
    onClickLeft () {
      this.$router.push('/home')
    },
    // 选择车间回调
    onConfirmCj (val) {
      this.addForm.workshopid = val.autoid
      this.addForm.workshop = val.workshop
      this.getWorkShopLineListData()
      this.showCjPicker = false
    },
    // 选择产线回调
    onConfirmCx (val) {
      this.addForm.lineid = val.autoid
      this.addForm.linecode = val.linecode
      this.addForm.linename = val.linename
      this.showCxPicker = false
    },
    // 获取车间产线
    getWorkShopLineListData () {
      getWorkShopLineList({
        workshopid: this.addForm.workshopid,
        username: this.userInfo.username
      }).then(res => {
        this.lineList = res.data
      })
    },
    // 获取车间列表
    getWorkShopListData () {
      getWorkShopList({
        username: this.userInfo.username
      }).then(res => {
        this.workshopList = res.data
      })
    },
    // 获取车间人员明细接口
    getLineListDetailData () {
      getLineListDetail({
        username: this.userInfo.username
      }).then(res => {
        let ary = res.data
        ary.forEach((ele, idx) => {
          ele.sqNum = idx + 1
          ele.checked = false
        });
        this.autoTableData = ary
      })
    },
    // 点击表格行
    changeAuditDataByTd (val) {
      this.autoTableData.forEach(ele => {
        if (val.autoid == ele.autoid)
        {
          ele.checked = !ele.checked
        }
      });
    },
    // 底部按钮切换
    checkFoot (val) {
      if (val == 'no')
      { // 删除  
        let ary = this.autoTableData.filter(it => { return it.checked })
        if (ary.length == 0)
        {
          Toast.fail('请选择表格记录！')
          return
        } else
        {
          let autoidlist = ary.map(it => { return it.autoid })
          Dialog.confirm({
            // title: '标题',
            message: this.$t('module.sureToDelete')
          }).then(() => {
            deleteLineEmpRecord({
              autoidlist: autoidlist.join(','),
              username: this.userInfo.username
            }).then(res => {
              if (res.data[0].info)
              {
                Toast.fail(res.data[0].info)
              } else
              {
                Toast.success(this.$t('module.DelSuc'))
                this.getLineListDetailData()
              }
            })
          }).catch(() => {
            // on cancel
          });
        }
      } else
      { // 新增
        this.showAddDia = true
        this.getWorkShopListData()
      }
    },
    // 弹窗回调
    submitAddPerson (action, done) {
      if (action == 'confirm')
      {
        if (!this.addForm.workshopid)
        {
          Toast.fail('请选择车间！')
          done(false)
          return
        }
        if (!this.addForm.lineid)
        {
          Toast.fail('请选择产线！')
          done(false)
          return
        }
        if (!this.addForm.empid)
        {
          Toast.fail('请选择员工！')
          done(false)
          return
        }
        this.addForm.username = this.userInfo.username
        saveLineEmpRecord(this.addForm).then(res => {
          if (res.data[0].info)
          {
            Toast.fail(res.data[0].info)
            done(false)
          } else
          {
            Toast.success('保存成功！')
            this.getLineListDetailData()
            done()
          }
        })
      } else
      {
        done()
      }
    },
    // 调起日历弹窗方法
    checkExDate (val) {
      this.witchDate = val
      this.showCalendar = true;
    },
    // 日期选择回调
    onConfirmCal (date) {
      if (this.witchDate == 'bd')
      {
        this.addForm.bd = this.formatDate(date, 'yyyy-MM-dd')
      } else
      {
        this.addForm.ed = this.formatDate(date, 'yyyy-MM-dd')
      }
      this.showCalendar = false;
    },
    // 选择员工
    checkEmp (item_tr) {
      this.checkRow = item_tr
      this.showEmpTreePop()
    },
    // 调起树
    showEmpTreePop () {
      this.$refs.refPersonnelPop.showPersonnelPop = true
    },
    // 选择人员回调
    getPersonnel (data) {
      let item = data[0]
      this.addForm.empname = item.empname;
      this.addForm.empid = item.empid;
      this.addForm.empcode = item.empcode
    },
    // 不够就补零
    add0 (m) {
      return m < 10 ? '0' + m : m
    },
    // 日期时间转换格式
    formatDate (date, rule) {
      if (date)
      {
        const time = new Date(date)
        const y = time.getFullYear()
        const m = time.getMonth() + 1
        const d = time.getDate()
        const h = time.getHours()
        const mm = time.getMinutes()
        const s = time.getSeconds()
        if (rule == 'yyyy-MM-dd')
        {
          return y + '-' + this.add0(m) + '-' + this.add0(d)
        } else
        {
          return (
            y +
            '-' +
            this.add0(m) +
            '-' +
            this.add0(d) +
            ' ' +
            this.add0(h) +
            ':' +
            this.add0(mm)
          )
          // + ':' + this.add0(s)
        }
      } else
      {
        return ''
      }
    },
  }

}
</script>

<style lang="less">
.addNewPerson_dia {
  .van-dialog__header {
    padding: 20px 0;
    background-image: url("~@/assets/img/job_booking09.png");
    background-repeat: no-repeat;
    background-size: 100%;
    color: #08306b;
    font-size: 40px;
    font-weight: bold;
    margin-bottom: 10px;
  }
}
.chanXianPersonnel_content {
  height: 100vh;
  background-image: url("~@/assets/img/job_booking01.png");
  overflow: hidden;
  background-color: #eaedef;
  background-repeat: no-repeat;
  background-size: 100%;
  background-position-y: 1.22667rem;
  position: relative;
  .van-nav-bar {
    height: 92px;
    background: #bcdfff;
    .van-nav-bar__left {
      .van-icon {
        color: #333;
        font-size: 0.5rem;
      }
      .van-nav-bar__text {
        color: #333;
        font-size: 36px;
        font-weight: bold;
      }
    }
    .van-nav-bar__title {
      color: #333;
      font-size: 36px;
      font-family: Source Han Sans CN;
    }
  }
  .content {
    height: calc(100% - 212px);
    margin-top: 92px;
    overflow-y: auto;
    .table_box {
      .table {
        width: 100%;
        border-spacing: 0px;
        tr {
          td:nth-child(1) {
            border-left: 2px solid #dcdfe6;
          }
          th,
          td {
            padding: 6px 10px;
            text-align: center;
            border-bottom: 2px solid #dcdfe6;
            border-right: 2px solid #dcdfe6;
            .comm_box {
              display: flex;
              justify-content: center;
              .boolean_box {
                .van-checkbox {
                  justify-content: center;
                  /deep/.van-checkbox__icon--checked {
                    .van-icon {
                      color: #fff !important;
                      background-color: #1989fa !important;
                      border-color: #1989fa !important;
                    }
                  }
                  /deep/.van-checkbox__icon--disabled .van-icon {
                    background-color: #fff;
                  }
                }
              }
              .input_box {
                width: 180px;
                .van-cell {
                  font-size: 24px;
                  border: 2px solid #d2d2d2;
                  border-radius: 10px;
                  padding: 8px 16px;
                }
              }
            }
          }
        }
        .top {
          position: sticky;
          top: 0;
          font-size: 28px;
          background: #e9f3fd;
          height: 64px;
          th:nth-child(1) {
            border-left: 2px solid #dcdfe6;
          }
          th {
            border-top: 2px solid #dcdfe6;
          }
        }
        .cont {
          height: 64px;
          font-size: 24px;
        }
        .cont:nth-child(2n-1) {
          background: #f5f5f5;
        }
        .nodata {
          td {
            font-size: 28px;
          }
        }
      }
    }
  }
  .footer {
    height: 120px;
    background: #fff;
    width: 100%;
    position: absolute;
    bottom: 0;
    font-size: 28px;
    display: flex;
    .f_btn {
      flex: 1;
      text-align: center;
      padding-top: 10px;
      .van-icon {
        font-size: 60px;
        // height: 60px;
      }
    }
  }
}
</style>